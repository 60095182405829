<template>
    <div class="tweetdeck-tweet" 
    :class="{'u-highlight-latest-item': processedTweet.new}"
    :data-tweet-uid="tweet.uid">

        <div class="tweetdeck-tweet--header">
            <div class="tweetdeck-tweet--header__image">
                <img :src="tweet.author.profile_image_url" alt="">
            </div>
            <div class="tweetdeck-tweet--header__name">
                <div class="tweetdeck-tweet--header__name-1">{{ tweet.author.fullName }}&nbsp;
                    <font-awesome-icon
                    class="verified-badge"
                    v-if="tweet.author.verified"
                    :icon="['fas', 'certificate']" /></div>
                <div class="tweetdeck-tweet--header__name-2">@{{ tweet.author.screenName }}</div>
            </div>
            <div class="tweetdeck-tweet--header__actions"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'twitter' }" /></div>
        </div>

        <div class="tweetdeck-tweet--body">

            <div v-html="processedTweet.text"></div>

            <div v-if="showGap && !videoTweet" style="text-align: center; margin-top: 1rem;">
                <social-image
                v-for="image in processedTweet.images"
                :key="tweet.uid + image.id"
                :image="image"></social-image>
            </div>
            
            <retweet-frame
            v-if="tweet.retweet"
            :retweets="retweets"
            ></retweet-frame>
            
        </div>

        <div class="tweetdeck-tweet--footer">
            <div class="tweetdeck-tweet--footer__actions">
                <a v-if="(tweet.thread || isUpperMostParent) && !threadMode" href="javascript:void(0)" class="show-twitter-thread"
                @click="showThread()"
                >Show thread</a>
            </div>
            <div class="tweetdeck-tweet--footer__time">{{ timestamp }}</div>
        </div>

    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { usersTime } from '../../../../utility/datetime';
import { getUrlParameters, isEmptyObject, isMobileScreen, sortDataBy } from '../../../../utility/helper-functions';
import SocialImage from './SocialImage';
import RetweetFrame from './RetweetFrame';

const tweetNS = createNamespacedHelpers('tweetdeck');
const general = createNamespacedHelpers("general");

export default {
    props: ["tweet","threadMode"],
    components: {
        SocialImage,
        RetweetFrame
    },
    created() {
        //clone for manipulation
        this.processedTweet = JSON.parse(JSON.stringify(this.tweet));
        this.processedTweet.images = [];

        let text = this.processedTweet.text;
        if(!this.tweet.quoted && this.tweet.retweet)
            text = '';

        this.processRetweets(this.processedTweet);

        let match = text.match(/\B@\w\w+/g);
        if(match)
            match.forEach(mention => {
                text = text.replace(mention, `<span class="twitter-highlight">${mention}</span>`)
            });
        match = text.match(/\B#\w\w+/g);
        if(match)
            match.forEach(hashtag => {
                text = text.replace(hashtag, `<span class="twitter-highlight">${hashtag}</span>`)
            });
        match = text.match(/<img.+?>/g);
        if(match)
            match.forEach(img => {
                text = text.replace(img, '');
                this.processedTweet.images.push({
                    id: this.processedTweet.images.length + 1,
                    html: img,
                    src: img.match(/src="(.*?)"/)[1]
                });
            });

        if(!isEmptyObject(this.tweet.weblinks) && this.tweet.quoted && !this.tweet.retweet) {
            text = this.processWeblinks(this.tweet, text);
        }

        // if(!isEmptyObject(this.tweet.videos) && this.tweet.quoted && !this.tweet.retweet) {
            text = this.processVideos(this.tweet, text);
        // }

        this.processedTweet.text = text;

        if(this.processedTweet.thread) {
            window.bootUp.iDB.getUpperMostParentInThread(this.processedTweet.thread).then(response => {
                if(response) {
                    // console.log("uppermost", response)
                    this.$store.dispatch("tweetdeck/addUpperMostParent", response);
                }
                    
            });
        }

        setTimeout(() => this.processedTweet.new = false, 1800);

    },
    computed: {
        ...tweetNS.mapGetters(["upperMostParents"]),
        ...general.mapGetters(['user']),
        timezone() {
            return this.user.timezone;
        },
        showGap() {
            return this.processedTweet && this.processedTweet.images && this.processedTweet.images.length
        },
        timestamp() {
            const time = usersTime (this.tweet.created_at, false, true, false, { timeFormat: "HH:mm"}, false, this.timezone);
            const date = usersTime (this.tweet.created_at, true, false, false, { dateFormat: "M d"}, false, this.timezone);
            return time + ' ' + date;
        },
        isUpperMostParent() {
            return this.upperMostParents.indexOf(this.tweet.uid) > -1;
        }
    },
    data() {
        return {
            processedTweet: null,
            retweets: [],
            retweetImages: {},
            videoTweet: false
        }
    },
    methods: {
        processRetweets(tweet) {
            if(tweet.retweet) {

                const images = [];

                let text = `RT @${tweet.retweet.author.screenName}: ${tweet.retweet.text}`;

                let match = text.match(/\B@\w\w+/g);
                if(match)
                    match.forEach(mention => {
                        text = text.replace(mention, `<span class="twitter-highlight">${mention}</span>`)
                    });
                match = text.match(/\B#\w\w+/g);
                if(match)
                    match.forEach(hashtag => {
                        text = text.replace(hashtag, `<span class="twitter-highlight">${hashtag}</span>`)
                    });
                match = text.match(/<img.+?>/g);
                if(match)
                    if(match)
                    match.forEach(img => {
                        text = text.replace(img, '');
                        
                            images.push({
                                id: images.length + 1,
                                html: img,
                                src: img.match(/src="(.*?)"/)[1]
                            });
                    });

                if(!isEmptyObject(tweet.retweet.weblinks)) {
                    text = this.processWeblinks(tweet.retweet, text);
                    
                }

                if(!isEmptyObject(tweet.retweet.videos)) {
                    text = this.processVideos(tweet.retweet, text);
                    
                }

                this.retweets.push({
                    images: images,
                    text: text,
                    thread: tweet.retweet.thread
                });


                this.processRetweets(tweet.retweet);

            }
        },
        processWeblinks(tweet, text) {
            for(let link in tweet.weblinks) {
                
                let id;
                let iframe = '';
                if(tweet.weblinks[link].expanded_url && tweet.weblinks[link].expanded_url.indexOf('youtu') > -1) {
                id = tweet.weblinks[link].expanded_url.match(/^(.+)\/([^/]+)$/)[2]; //original regex /^(.+)\/([^\/]+)$/
                if(id.indexOf('v=') > -1) {
                    const params = getUrlParameters(tweet.weblinks[link].expanded_url)
                    id = params['v'];
                }
                    iframe = `
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/${id}?controls=0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    `;

                    
                }

                if(tweet.weblinks[link].expanded_url && tweet.weblinks[link].expanded_url.indexOf('vimeo') > -1) {
                    id = tweet.weblinks[link].expanded_url.match(/^(.+)\/([^/]+)$/)[2]; //original regex /^(.+)\/([^\/]+)$/
                    iframe = `
                        <iframe src="https://player.vimeo.com/video/${id}" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                    `;

                    
                }
                if(iframe) {
                    const match = text.match(/<\s*a[^>]*>(.*?)<\s*\/\s*a>/g);
                    if(match) {
                        // const rule = new RegExp(`/href="${link}"/`);
                        match.forEach(element => {
                            if(element.indexOf(link) > -1) {
                                text = text.replace(element, iframe);
                            }
                        })
                
                    }

                    // text = text + iframe;
                    // text = text.replace(aTag,iframe);
                }
                    
            }

            return text;
        },
        processVideos(tweet, text) {
            for(let video in tweet.videos) {
                let url;
                let videoArray = [];

                let bitRateProp = "";
                for(let versions in tweet.videos[video]) {
                    if(tweet.videos[video][versions].bitrate) {//legacy api
                        videoArray.push(tweet.videos[video][versions]);
                        bitRateProp = "bitrate";
                    }
                    else if(tweet.videos[video][versions].bit_rate || 
                    tweet.videos[video][versions].bit_rate === 0) {
                        videoArray.push(tweet.videos[video][versions]);
                        bitRateProp = "bit_rate";
                    }
                }


                videoArray = sortDataBy(videoArray, bitRateProp, 'orderbyDESC');
                this.videoTweet = true;

                if(isMobileScreen()) {
                    url = videoArray.lenght > 1 ? videoArray[1]?.url : videoArray[0]?.url;
                }else{
                    url = videoArray[0]?.url;
                }

                if(url) {

                    let iframe = '';

                    const match1 = text.match(/<img.*?src="(.*?thumb.*?)".*>/g);
                    iframe = `
                        <video width="100%" height="100%" controls>
                            <source src="${url}" type="video/mp4">
                        </video>
                    `;
                    if(match1) {
                        text = text.replace(match1,iframe);
                    }

                    const match2 = text.match(/<img.*?src="(.*?twimg.*?)".*>/g);
                    iframe = `
                        <video width="100%" height="100%" controls>
                            <source src="${url}" type="video/mp4">
                        </video>
                    `;
                    
                    if(match2) {
                        text = text.replace(match2,iframe);
                    }

                    if(!match1 && !match2) {
                        text = text + iframe;
                    }
                }
            }

            return text;
        },
        showThread() {
            this.$emit("show-thread", this.tweet);
        }
        
    }
    
}
</script>